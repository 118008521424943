import { Fragment } from "react";

export const menu = {
  dashboard: "DASHBOARD",
  deployment: "DEPLOYMENT",
  authoring: "AUTHORING",
  configuration: "CONFIGURATION",
  logout: "LOGOUT",
};

export const configuration = {
  title: "Manage Equipments",
  list: {
    title: "Choose which case base to view its details.",
  },
  empty: {
    configuration_list: {
      title: "",
      subtitle: "",
      body: "",
    },
    configuration_card: {
      title: "Manage Case base",
      subtitle: "Choose which case base to view its details.",
      body: () => (
        <Fragment>
          <div id="tasks-progress">
            <p className="fw-bold text-center">
              Select the case base to proceed
            </p>
            <ul>
              <li>
                Select the case base from the list on the left to load it here.
              </li>
              <li>Once loaded, Add or Edit the existing fields.</li>
              <li>Once done editing, click on "Save" to save the document.</li>
            </ul>
          </div>
        </Fragment>
      ),
    },
  },
  configuration_card: {
    title: "Manage case base",
    subtitle: "The description for the goes here...",
  },
  form: {
    name: "Name",
    prd_ln: "Product Line",
    product_line_tooltip:
      "Disabled! If any [Tree] or [Model List] is assigned to the product line.",
    description: "Description",
    key: "Key",
    tags: "Tags",
    tags_tooltip: "Enter tags",
  },
};

export const common = {
  btn_edit: "Edit",
  btn_ok: "Okay",
  btn_search: "Search",
  btn_save: "Save",
  btn_new: "New",
  btn_new_document: "New Document",
  btn_close: "Close",
  btn_submit: "Submit",
  btn_reset: "Reset",
  btn_cancel: "Cancel",
  btn_download: "Download",
  btn_upload: "Upload",
  all: "All",
  name: "Name",
  enter: "Enter",
  enter_text: "Enter Text",
  notes: "Notes",
  deploy_to: "Deploy to",
  draft: "draft",
  staging: "staging",
  production: "production",
  new_document: "New Document",
  document_tree: "Document Tree",
  model_list: "Model List",
  document_type: "Document Type",
  decision_tree: "Decision Tree",
  filter_document: "Filter the documents",
  deploy_help_text: "Need to deploy on ",
  option_text: "Option text",
  question_text: "Question Text",
  solution_text: "Solution Text",
  messages: {
    msg: "msg",
  },
};

export const authoring = {
  empty: {
    tree: {
      title: "Choose a Document to Continue",
      list: {
        item_1:
          "Pick a document from the options on the left to display it here.",
        item_2:
          "After loading, you can explore questions and solutions by hovering over and clicking on the nodes of the tree.",
        item_3: "For extra actions, right-click on a node.",
        item_4: `When you're finished editing, select "Save Document" to save your changes.`,
      },
    },
    model: {
      title: "To update this document, follow these steps",
      list: {
        item_1: "Click the Download button to obtain a copy of the document.",
        item_2: "Make necessary updates to the downloaded copy.",
        item_3:
          "Click the Upload button to replace the existing document with your modified version.",
        item_4:
          "Remember that uploading will overwrite the contents of the original document.",
      },
    },
  },
  emptyFilter: {
    title: "Document not found!",
    body: "Sorry, the requested documents could not be found. Please check back later or contact support for assistance.",
  },
  newDocument: {
    target_equipment: "Target Equipment",
    select_target_equipment: "Select Target Equipment",
    select_document_type: "Select Document Type",
    new_document_name: "New Document Name",
  },
  document_property: {
    empty: {
      title: "No Selection!",
      body: "To view or edit, please select a node or option from the tree.",
    },
  },
  newNode: {
    add_qo: "Add Question With Option",
    add_os: "Add Solution With Option",
  },
};
