import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginWithRedirect();
    navigate("/dashboard");
  };

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card gradient-custom text-white"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5">
                <div className="mb-md-5 mt-md-4 pb-5">
                  <h2 className="fw-bold mb-2 text-uppercase text-center">
                    Login
                  </h2>
                  <p className="text-white-50 mb-3 text-center">
                    Log in to access the application
                  </p>
                  <p className="text-white-50 mb-5">
                    On clicking the Login button, you will be redirected to your
                    organization's authentication page. Once successfully
                    authenticated, you will be automatically forwarded to the
                    application launch page.
                  </p>
                  <form className="text-center" onSubmit={handleSubmit}>
                    <button
                      className="btn btn-outline-light btn-lg px-5"
                      type="submit"
                    >
                      <i className="fas fa-shield-alt"></i> Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
