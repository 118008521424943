import _isEmpty from 'lodash/isEmpty';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AuthoringDocumentFilter,
  Document,
  DocumentList,
  EmptyCard,
  EmptyDocument,
  Loading,
  NewDocumentForm,
} from '../../components';

import { useModal } from '../../context/ModalContext';
import { authoring, common } from '../../utills/static-text';

import useGetEquipments from '../../hooks/getEquipments';
import useGetQuestions from '../../hooks/getQuestions';

const Authoring = () => {
  const { treeId = '' } = useParams();
  const questions = useGetQuestions();

  const showModal = useModal();
  const [selectedFilter, setSelectedFilter] = useState('');
  const [documentProperty, setDocumentProperty] = useState();
  const [checkDecisionTree, setCheckDecisionTree] = useState(true);
  const [checkModelList, setCheckModelList] = useState(true);
  const filterByConf = useGetEquipments();

  const filteredQueData = questions?.data?.filter(
    (item) =>
      (checkDecisionTree && item.doc_id === 1) ||
      (checkModelList && item.doc_id === 2)
  );

  const handelAddDocument = (event) => {
    event.preventDefault();
    showModal({
      className: 'new-document-model',
      title: (
        <>
          <i className="fa-solid fa-file"></i> {common.new_document}
        </>
      ),
      body: (
        <>
          <NewDocumentForm filterByConf={filterByConf?.data} />
        </>
      ),
    });
  };

  const onDocumentUpdate = () => {
    // dispatch(getDocumentList());
    // TODO: need to check it again
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        <section id="sidebar" className="sidebar authoring">
          <AuthoringDocumentFilter
            filterByConf={filterByConf?.data}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setCheckDecisionTree={setCheckDecisionTree}
            checkDecisionTree={checkDecisionTree}
            setCheckModelList={setCheckModelList}
            checkModelList={checkModelList}
            handelAddDocument={handelAddDocument}
          />
          {!_isEmpty(filteredQueData) ? (
            <Fragment>
              {checkDecisionTree || checkModelList ? (
                <DocumentList
                  filteredData={filteredQueData?.filter((item) =>
                    selectedFilter
                      ? selectedFilter?.prd_ln === item.prd_ln
                      : item
                  )}
                  setDocumentProperty={setDocumentProperty}
                />
              ) : (
                <EmptyCard
                  title={authoring.emptyFilter.title}
                  body={authoring.emptyFilter.body}
                />
              )}
            </Fragment>
          ) : filteredQueData && filteredQueData?.length === 0 ? (
            <EmptyCard
              title={authoring.emptyFilter.title}
              body={authoring.emptyFilter.body}
            />
          ) : (
            <Loading isIconOnly={true} />
          )}
        </section>
        <section className="main">
          {!treeId ? (
            <EmptyDocument
              {...{
                title: authoring.empty.tree.title,
                item_1: authoring.empty.tree.list.item_1,
                item_2: authoring.empty.tree.list.item_2,
                item_3: authoring.empty.tree.list.item_3,
                item_4: authoring.empty.tree.list.item_4,
              }}
            />
          ) : (
            <Document
              onDocumentUpdate={onDocumentUpdate}
              documentProperty={documentProperty}
              setDocumentProperty={setDocumentProperty}
            />
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default Authoring;
