import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const showAlert = (message, variant = 'info') => {
    setAlert({ message, variant });
    setTimeout(() => setAlert(null), 5000); // Auto-dismiss after 5 seconds
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert && (
        <div className={`alert alert-${alert.variant}`} style={{
            position: 'fixed',
            bottom: '15px', // Adjust as needed
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '9999', // Ensure the alert is above other content
          }} role="alert">
          {alert.message}
        </div>
      )}
    </AlertContext.Provider>
  );
};
