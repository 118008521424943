import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AppRoutes from "./routes";
import { AlertProvider } from "./context/AlertContext";
import { ModalProvider } from "./context/ModalContext";
import { DocumentLockProvider } from "./context/DocumentLockContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <DocumentLockProvider>
        <ModalProvider>
          <AlertProvider>
            <AppRoutes />
          </AlertProvider>
        </ModalProvider>
      </DocumentLockProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
