/* eslint-disable no-console */
/**
 * 
 * Log all action and states after thay are dispatched.
 */
const logger = store => next => action => {
    const result = next(action);
    return result;
}

export default logger;