import React from "react";

const EmptyDocument = ({ title, item_1, item_2, item_3, item_4 }) => {
  return (
    <React.Fragment>
      <div className="authoring-content full">
        <h2 className="mb-md-3 mb-4">{title}</h2>
        <ul>
          <li>{item_1}</li>
          <li>{item_2}</li>
          <li>{item_3}</li>
          <li>{item_4}</li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default EmptyDocument;
