import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { EmptyCard, DocumentCard, Loading } from '../../components';
// import DocumentCard from "./DocumentCard";
import useGetEquipments from '../../hooks/getEquipments';

const DocumentList = ({ filteredData, setDocumentProperty }) => {
  const filterconfdata = useGetEquipments();

  function addCategoryName(source, target) {
    const sourceMap = new Map();
    source?.forEach((item) => {
      sourceMap.set(item.prd_ln, item.name);
    });

    target?.forEach((item) => {
      if (sourceMap.has(item.prd_ln)) {
        item.categ_name = sourceMap.get(item.prd_ln);
      }
    });
    return target;
  }

  const data = addCategoryName(filterconfdata?.data, filteredData);

  return (
    <Fragment>
      <div className="sidebar-content">
        <div className="row">
          {data ? (
            data?.map((item) => (
              <Link
                to={`/authoring/${item.doc_id === 1 ? 'tree' : 'model'}/${
                  item.prd_ln
                }/${item.start_id}/${item.id}`}
                key={`link-${item.id}-${item.id}`}
                onClick={() => setDocumentProperty(item)}
                className="text-decoration-none display-block"
              >
                <div className="col-12 col-sm-12 col-xs-12 mb-2 d-flex">
                  <DocumentCard item={item} isDeployment={false} />
                </div>
              </Link>
            ))
          ) : false ? (
            <Loading isIconOnly={true} />
          ) : (
            <EmptyCard
              title="Document not found!"
              body="Sorry, the requested documents could not be found. Please check back later or contact support for assistance."
              className="sticky-top"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DocumentList;
