import { useQueryClient } from "@tanstack/react-query";
import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DocumentHeader,
  DocumentProperty,
  EmptyDocument,
  Loading,
  SVGComponent,
  TreeNode,
} from "..";
import { useModal } from "../../context/ModalContext";
import useGetQuestionHeaderData from "../../hooks/getQuestionHeaderData";
import useGetTreeData from "../../hooks/getTreeData";
import {
  deployTreeData,
  getSearchTextData,
  lockTreeData,
} from "../../store/actions/authoring.actions";
import { authoring, common } from "../../utills/static-text";
import { renderHtmlContent } from "../../utills/utills";
import UploadModelListForm from "./UploadModelListForm";
import useDownloadModelList from "../../hooks/downloadModelList";
import { handleDownloadCsv } from "../../utills/handle-download-csv";
const Document = ({
  onDocumentUpdate,
  documentProperty,
  setDocumentProperty,
}) => {
  const dispatch = useDispatch();
  const showModal = useModal();
  const navigate = useNavigate();
  const { prdLn = "", treeId = "", docType = "" } = useParams();
  const [searching, setSearching] = useState(false);
  const documentData = useGetQuestionHeaderData({
    prd_ln: prdLn,
    id: treeId,
  });
  const downloadModelList = useDownloadModelList({
    prdLn: prdLn,
    treeId: treeId,
  });
  const { data, isLoading: load } = useGetTreeData({
    prd_ln: prdLn,
  });

  const theData = useMemo(() => {
    if (!data) return [];
    return data.data.data; // Adjust according to the actual data structure
  }, [data]);

  const { searchTextData } = useSelector((state) => state.authoring);
  const [deploymentOptions, setDeploymentOptions] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const isDocumentLocked = useRef(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!documentData?.data?.[0]) return;

    const deploymentOptionsMap = {
      draft: [
        {
          name: common.staging,
          variant: "Warning",
          disabled: false,
          msg: common.deploy_help_text + common.staging,
        },
      ],
      staging: [
        {
          name: common.production,
          variant: "Success",
          disabled: false,
          msg: common.deploy_help_text + common.production,
        },
      ],
      production: [
        {
          name: common.production,
          variant: "Success",
          disabled: true,
          msg: "Data is up-to-date on " + common.production,
        },
      ],
    };

    const defaultOptions = [
      {
        name: common.staging,
        variant: "Warning",
        disabled: false,
        msg: common.deploy_help_text + common.staging,
      },
    ];

    const deploymentOptions =
      deploymentOptionsMap[documentData?.data?.[0]?.deploy_status] ||
      defaultOptions;

    isDocumentLocked.current = documentData?.data?.[0]?.islocked;

    setDeploymentOptions(deploymentOptions);
  }, [documentData?.data]);

  const handleDocumentIsEdit = () => {
    dispatch(
      lockTreeData({
        query: { prd_ln: prdLn, id: treeId, bool: !isDocumentLocked.current },
      })
    ).then(() => {
      queryClient.invalidateQueries({ queryKey: ["questions"] });
      documentData.refetch();
      onDocumentUpdate();
    });

    isDocumentLocked.current = documentData?.data?.[0]?.islocked;
  };

  const handleCloseModal = () => {
    showModal(null);
    navigate("/authoring");
  };

  const handelDocumentClose = () => {
    if (isDocumentLocked.current) {
      showModal({
        title: "Are you sure?",
        body: "The document is locked and will remain locked if you close it without unlocking it.",
        footer: (
          <div>
            <Button
              variant="primary"
              className="me-2"
              onClick={handleCloseModal}
            >
              {common.btn_ok}
            </Button>
          </div>
        ),
      });
    } else {
      navigate("/authoring");
    }
  };

  const handleDeployment = () => {
    dispatch(
      deployTreeData({
        query: { env: deploymentOptions[0].name, prd_ln: prdLn, id: treeId },
      })
    ).then(() => {
      setIsUpdate(!isUpdate);
      onDocumentUpdate();
      handleCloseModal();
    });
  };

  const showConfirmDeploy = () => {
    showModal({
      title: "Are you sure?",
      body: `${deploymentOptions[0].msg}`,
      footer: (
        <div>
          <Button
            variant="danger"
            className="me-2"
            onClick={() => showModal(null)}
          >
            Cancel
          </Button>
          <Button variant="primary" className="me-2" onClick={handleDeployment}>
            Save
          </Button>
        </div>
      ),
    });
  };

  const handleDownload = () => {
    handleDownloadCsv({
      jsonData: downloadModelList?.data?.data,
      fileName: documentProperty?.name,
    });
  };

  const handleUpload = () => {
    showModal({
      title: "Upload Model List",
      body: <UploadModelListForm documentProperty={documentProperty} />,
    });
  };

  const onSearchText = (searchTerm) => {
    setSearching(searchTerm !== "");

    if (searchTerm !== "") {
      const payload = { searchtext: searchTerm };

      dispatch(
        getSearchTextData({
          body: payload,
          method: "POST",
          query: { prd_ln: prdLn },
        })
      ).then(() => {
        setSearching(true);
      });
    } else {
      setSearching(false);
    }
  };

  const setIcon = (nodeType, id) => {
    switch (nodeType) {
      case "question":
        return (
          <span className="svg-question me-2">
            <SVGComponent.Question />
          </span>
        );
      case "solution":
        return (
          <span className="svg-success me-2">
            <SVGComponent.Solution />
          </span>
        );
      default:
        return (
          <span className="svg-dark me-2">
            <SVGComponent.Option />
          </span>
        );
    }
  };

  return (
    <Fragment>
      {documentData.isLoading ? (
        <Loading isIconOnly={true} />
      ) : (
        <DocumentHeader
          documentData={documentData}
          isDocumentLocked={isDocumentLocked.current}
          deploymentOptions={deploymentOptions}
          handleDeployment={handleDeployment}
          handleDownload={handleDownload}
          handleUpload={handleUpload}
          handleDocumentIsEdit={handleDocumentIsEdit}
          handelDocumentClose={handelDocumentClose}
          docType={docType}
          showConfirmDeploy={showConfirmDeploy}
          onSearchText={onSearchText}
        />
      )}

      <div className="authoring-content">
        <section className={docType === "model" ? "main" : "document-content"}>
          {docType === "model" ? (
            <EmptyDocument
              title={authoring.empty.model.title}
              item_1={authoring.empty.model.list.item_1}
              item_2={authoring.empty.model.list.item_2}
              item_3={authoring.empty.model.list.item_3}
              item_4={authoring.empty.model.list.item_4}
            />
          ) : (
            <Fragment>
              {load ? (
                <Loading isIconOnly={true} />
              ) : theData.length <= 0 ? (
                <h2>No data found!</h2>
              ) : (
                theData.map((rootNode) => (
                  <TreeNode
                    key={`tree-node-${rootNode.m_id}-${treeId}`}
                    node={rootNode}
                    documentProperty={documentProperty}
                    setDocumentProperty={setDocumentProperty}
                    isDocumentLocked={isDocumentLocked.current}
                    handleDocumentIsEdit={handleDocumentIsEdit}
                  />
                ))
              )}
            </Fragment>
          )}
        </section>
        <section
          className={
            !_isEmpty(documentProperty) || searching
              ? isDocumentLocked.current
                ? "document-property"
                : documentProperty?.doc_id === 2
                ? "document-property"
                : "bg-locked document-property"
              : "d-none"
          }
        >
          <div className="container pt-3">
            <Tabs
              defaultActiveKey={searching ? "searchresults" : "properties"}
              id="uncontrolled-tab"
              className="mb-3 properties-tabs"
            >
              <Tab eventKey="properties" title="Properties">
                <DocumentProperty
                  documentProperty={documentProperty}
                  isDocumentLocked={isDocumentLocked.current}
                />
              </Tab>
              {documentProperty?.doc_id === 2
                ? false
                : true && (
                    <Tab eventKey="searchresults" title="Search Results">
                      <div className="row">
                        <div col="col-xs-12">
                          <b>Questions</b>
                          <ul className="list-unstyled">
                            {searchTextData?.question?.map((item) => (
                              <li key={item.id}>
                                {setIcon(item.ques_type, item.id.toString())}{" "}
                                <Link
                                  to={`/authoring/tree/${prdLn}/1/${treeId}/edit-node/${item.ques_type}/${item.start_id}/${item.id}`}
                                  className="text-desc d-inline-block my-1"
                                  dangerouslySetInnerHTML={renderHtmlContent(
                                    item.raw_content.substring(0, 25) + "..."
                                  )}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div col="col-xs-12">
                          <b>Solution</b>
                          <ul className="list-unstyled">
                            {searchTextData?.solution?.map((item) => (
                              <li key={item.id}>
                                {setIcon(item.ques_type, item.id.toString())}{" "}
                                <Link
                                  to={`/authoring/tree/${prdLn}/1/${treeId}/edit-node/${item.ques_type}/${item.start_id}/${item.id}`}
                                  className="text-desc d-inline-block my-1"
                                  dangerouslySetInnerHTML={renderHtmlContent(
                                    item.raw_content.substring(0, 25) + "..."
                                  )}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div col="col-xs-12">
                          <b>Option</b>
                          <ul className="list-unstyled">
                            {searchTextData?.option?.map((item) => (
                              <li key={item.id}>
                                {setIcon(item.ques_type, item.id.toString())}{" "}
                                <Link
                                  to={`/authoring/tree/${prdLn}/1/${treeId}/edit-node/${item.ques_type}/${item.start_id}/${item.id}`}
                                  className="text-desc d-inline-block my-1"
                                  dangerouslySetInnerHTML={renderHtmlContent(
                                    item.raw_content.substring(0, 25) + "..."
                                  )}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab>
                  )}
            </Tabs>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Document;
