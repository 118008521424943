import React from 'react';
import { handleDownloadCsv } from '../../utills/handle-download-csv';
import { Button, Card } from 'react-bootstrap';

const SampleCSVTable = ({ common }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div>
          <span className="text-danger text-uppercase">{common.notes}</span>
          {': '}
          The text CSV format files strictly follow the structure shown below
          mandatory<span className="text-danger">*</span> fields:
          <div className="fs-smaller ff-monospace">
            MODEL_NO, PRODUCT_LINE_CODE, PRODUCT_TYPE, PRODUCT_SUBTYPE
          </div>
        </div>
        <div className="overflow-auto">
          <table className="table table-bordered fs-smaller">
            <thead>
              <tr>
                <th>
                  MODEL_NO<span className="text-danger">*</span>
                </th>
                <th>
                  PRODUCT_LINE_CODE
                  <span className="text-danger">*</span>
                </th>
                <th>
                  PRODUCT_TYPE<span className="text-danger">*</span>
                </th>
                <th>
                  PRODUCT_SUBTYPE
                  <span className="text-danger">*</span>
                </th>
                <th>MD_TYPE</th>
                <th>MD_SUBTYPE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button
          variant="outline-flat"
          onClick={handleDownloadCsv}
          className="btn btn-link"
        >
          <i className="fa-solid fa-file-csv"></i> Download sample CSV file
        </Button>
      </Card.Body>
    </Card>
  );
};

export default SampleCSVTable;
