import React, { createContext, useContext, useState } from "react";

const DocumentLockContext = createContext();

export const DocumentLockProvider = ({ children }) => {
  const [isDocumentLocked, setIsDocumentLocked] = useState(false);

  const toggleDocumentLock = () => {
    setIsDocumentLocked(!isDocumentLocked);
  };

  const setDocumentLock = (locked) => {
    setIsDocumentLocked(locked);
  };

  return (
    <DocumentLockContext.Provider
      value={{ isDocumentLocked, toggleDocumentLock, setDocumentLock }}
    >
      {children}
    </DocumentLockContext.Provider>
  );
};

export const useDocumentLock = () => {
  return useContext(DocumentLockContext);
};
