// import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SVGComponent } from ".";
import { menu } from "../utills/static-text";

const Navbar = ({ user }) => {
  const { profile } = useSelector((state) => state.user);
  // const { logout } = useAuth0();

  return (
    <nav
      data-mdb-navbar-init
      className="navbar navbar-expand-lg navbar-light bg-body-tertiary p-0 border-bottom"
    >
      <div className="container-fluid">
        <a className="navbar-brand mt-2 mt-lg-0" href="/">
          <SVGComponent.LogoSvg />
        </a>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item fs-5">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                {menu.dashboard}
              </NavLink>
            </li>
            <li className="nav-item fs-5">
              <NavLink
                to="/authoring"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                {menu.authoring}
              </NavLink>
            </li>
            <li className="nav-item fs-5">
              <NavLink
                to="/deployment"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                {menu.deployment}
              </NavLink>
            </li>
            <li className="nav-item fs-5">
              <NavLink
                to="/configuration"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                {menu.configuration}
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-center">
          <div className="fw-bold">
            {profile?.firstName} {profile?.lastName}
          </div>
          {/* <div className="dropdown d-inline-block position-relative">
            <a
              className="btn btn-light bg-tertiary shadow-sm dropdown-toggle"
              href="#dropdown"
              data-bs-toggle="dropdown"
              data-bs-display="static"
            >
              {profile?.firstName} {profile?.lastName}
            </a>

            <div className="dropdown-menu dropdown-menu-end">
              <Link
                to="/logout"
                onClick={() => logout()}
                className="dropdown-item"
              >
                {menu.logout}
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
