import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";
const useUpdateTreeDataStage = () => {
  const updateTreeDataStage = async ({ body, stage }) => {
    const baseURL = `${process.env.REACT_APP_API_ORIGIN}/v1/api/deploy/${stage}`;
    return await axios.put(baseURL, body, { headers });
  };
  return useMutation({
    mutationFn: updateTreeDataStage,
  });
};

export default useUpdateTreeDataStage;
