import React from 'react';

const TotalCards = ({ getDashboardData }) => {
  const { statistics } = getDashboardData?.data || {};
  const { total, distinct, duplicates = [] } = statistics || {};

  const cardData = [
    {
      title: total,
      subtitle: 'Total documents for all case base',
      iconClass: 'fa-file',
      bgColor: 'bg-info',
    },
    {
      title: distinct,
      subtitle: 'Total decision trees for all case base',
      iconClass: 'fa-file',
      bgColor: 'bg-success',
    },
    {
      title: duplicates.length,
      subtitle: 'Total model list for all case base',
      iconClass: 'fa-copy',
      bgColor: 'bg-danger',
    },
  ];

  return (
    <div className="row mb-4">
      {cardData.map(({ title, subtitle, iconClass, bgColor }, index) => (
        <div className="col-lg-4" key={index}>
          <div className={`card ${bgColor} text-white flex-fill`}>
            <div className="card-body py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h2 className="mb-2">{title}</h2>
                  <p className="mb-2">{subtitle}</p>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <i className={`fa-solid ${iconClass} fs-4`}></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalCards;
