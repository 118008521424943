const appInitialState = {
  configuration: {
    equipmentListData: [],
    equipmentData: {
      id: null,
      prd_ln: null,
      name: null,
      description: null,
      key: null,
      tags: null,
    },
    problemSearch: [],
  },
  authoring: {
    documentList: [],
    documentData: [],
    treeData: [],
    newDocument: {},
    copyNode: {},
    linkNode: {}
  },
  user: {
    profile: {},
    error: null,
  }
};

export default appInitialState;
