import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useGetDashboardData = (activeCaseBase) => {
  const getDashboardData = async () => {
    const baseURL = `${
      process.env.REACT_APP_API_ORIGIN
    }/v1/api/dashboard/${encodeURIComponent(activeCaseBase)}`;
    const data = await axios.get(baseURL, { headers });
    return data?.data?.data;
  };

  return useQuery({
    queryFn: getDashboardData,
    queryKey: ["dashboardData", activeCaseBase],
    refetchOnWindowFocus: true,

    retry: 3, // Retry the query up to 3 times in case of failure
    staleTime: 30000, // 30 seconds
    cacheTime: 5 * 60 * 1000, // 5 minutes

    onError: (error) => {
      console.error("Error fetching questions:", error);
    },
  });
};

export default useGetDashboardData;
