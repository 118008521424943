import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Loading } from '..';
import { authoring, common } from '../../utills/static-text';

const ModalComponent = ({
  show,
  handleCloseModal,
  handleFormSubmit,
  formAction,
  inputOptionText,
  setInputOptionText,
  formErrors,
  setFormErrors,
  inputQuestionText,
  setInputQuestionText,
  inputSolutionText,
  setInputSolutionText,
}) => {
  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {' '}
          {formAction === 'add_child_question'
            ? authoring.newNode.add_qo
            : authoring.newNode.add_os}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {false ? (
          <Loading isIconOnly={true} />
        ) : (
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>{common.option_text}</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                type="text"
                placeholder={common.name}
                value={inputOptionText}
                onChange={(e) => {
                  setInputOptionText(e.target.value);
                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    inputOptionText: '',
                  }));
                }}
              />
              {formErrors.inputOptionText && (
                <p className="text-danger">{formErrors.inputOptionText}</p>
              )}
            </Form.Group>

            {formAction === 'add_child_question' ? (
              <Form.Group className="mb-3">
                <Form.Label>{common.question_text}</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  placeholder={common.enter_text}
                  value={inputQuestionText}
                  onChange={(e) => {
                    setInputQuestionText(e.target.value);
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      inputQuestionText: '',
                    }));
                  }}
                />
                {formErrors.inputQuestionText && (
                  <p className="text-danger">{formErrors.inputQuestionText}</p>
                )}
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>{common.solution_text}</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  placeholder={common.enter_text}
                  value={inputSolutionText}
                  onChange={(e) => {
                    setInputSolutionText(e.target.value);
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      inputSolutionText: '',
                    }));
                  }}
                />
                {formErrors.inputSolutionText && (
                  <p className="text-danger">{formErrors.inputSolutionText}</p>
                )}
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="secondary" onClick={handleFormSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
