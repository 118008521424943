import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.scss';

import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';

import { Auth0Provider } from '@auth0/auth0-react';
import { ModalProvider } from './context/ModalContext';

const storeConfig = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <storeConfig.Provider store={storeConfig.store}>
      <ModalProvider>
        <Auth0Provider
          domain={process.env.HEROKU_APP_DOMAIN}
          clientId={process.env.HEROKU_APP_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <App />
        </Auth0Provider>
      </ModalProvider>
    </storeConfig.Provider>
  </React.StrictMode>
);

reportWebVitals();
