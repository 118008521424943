import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useCreateDocumentData = () => {
  const createDocument = async (data) => {
    const baseURL = `${process.env.REACT_APP_API_ORIGIN}/v1/api/questions`;
    return await axios.post(baseURL, data, { headers });
  };
  return useMutation({
    mutationFn: createDocument,
  });
};

export default useCreateDocumentData;
