import { Link, useParams } from "react-router-dom";
import { EmptyCard, Loading } from "../../components";
import ConfigurationCard from "../../components/Configuration/ConfigurationCard";
import ConfigurationDetails from "../../components/Configuration/ConfigurationDetails";
import { common, configuration } from "../../utills/static-text";
import useGetEquipments from "../../hooks/getEquipments";
import _isEmpty from "lodash/isEmpty";

const Configuration = ({ mode }) => {
  const equipmentListData = useGetEquipments();
  const { confId, prdLn } = useParams();

  return (
    <div className="wrapper">
      <div className="container mx-auto mx-5 p-5">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-4">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">{configuration.title}</h5>
                <h6 className="card-subtitle text-muted">
                  {configuration.list.title}
                </h6>
              </div>
              <div className="card-body">
                <div id="tasks-upcoming">
                  {equipmentListData.isLoading ? (
                    <Loading isIconOnly={true} />
                  ) : (
                    equipmentListData?.data?.map((item) => (
                      <ConfigurationCard item={item} key={item.id} />
                    ))
                  )}

                  {equipmentListData &&
                    equipmentListData?.data?.length === 0 && (
                      <>
                        <p>No case base availabe!</p>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-8">
            {_isEmpty(mode) ? (
              <>
                {equipmentListData && equipmentListData?.data?.length === 0 ? (
                  <EmptyCard
                    title={configuration.empty.configuration_card.title}
                    subtitle="No case base availabe!"
                    body={<p>Please create new case base.</p>}
                    className="sticky-top"
                  />
                ) : (
                  <EmptyCard
                    title={configuration.empty.configuration_card.title}
                    subtitle={configuration.empty.configuration_card.subtitle}
                    body={configuration.empty.configuration_card.body()}
                    className="sticky-top"
                  />
                )}
              </>
            ) : (
              <ConfigurationDetails confId={confId} prdLn={prdLn} mode={mode} />
            )}
          </div>
        </div>
        <div className="dropup position-fixed bottom-0 end-0 rounded-circle m-5 zindex-modal">
          {(!confId || (confId && confId !== "new")) && (
            <Link
              to="/configuration/add/new"
              className="btn btn-outline-primary me-2"
            >
              <i className="fa-solid fa-add"></i> {common.btn_new}
            </Link>
          )}
          {(confId || confId === "new") && (
            <Link to="/configuration" className="btn btn-outline-danger">
              <i className="fa-solid fa-xmark"></i> {common.btn_close}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
