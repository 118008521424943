import { ReactComponent as Add } from "../../images/add.svg";
import { ReactComponent as Close } from "../../images/close.svg";
import { ReactComponent as DecisionTree } from "../../images/decision-tree.svg";
import { ReactComponent as DoubleChevronRight } from "../../images/double-chevron-right.svg";
import { ReactComponent as DragHandel } from "../../images/drag-handel.svg";
import { ReactComponent as Filter } from "../../images/filter.svg";
import { ReactComponent as LogoSvg } from "../../images/ge-appliances-logo.svg";
import { ReactComponent as Loading } from "../../images/loading.svg";
import { ReactComponent as Lock } from "../../images/lock.svg";
import { ReactComponent as LogOut } from "../../images/log-out.svg";
import { ReactComponent as ModelList } from "../../images/model-list.svg";
import { ReactComponent as NewDocument } from "../../images/new-document.svg";
import { ReactComponent as NoDocuments } from "../../images/no-documents.svg";
import { ReactComponent as Question } from "../../images/question.svg";
import { ReactComponent as Refresh } from "../../images/refresh.svg";
import { ReactComponent as SaveAs } from "../../images/save-as.svg";
import { ReactComponent as Solution } from "../../images/solution.svg";
import { ReactComponent as Option } from "../../images/option.svg";

const SVGComponent = {
  Add,
  Close,
  DecisionTree,
  DoubleChevronRight,
  DragHandel,
  Loading,
  Lock,
  LogoSvg,
  LogOut,
  ModelList,
  NewDocument,
  NoDocuments,
  Refresh,
  SaveAs,
  Filter,
  Question,
  Solution,
  Option,
};

export default SVGComponent;
