import _isEmpty from 'lodash/isEmpty';
import { OverlayTrigger } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DateFormatter, GETooltip, SVGComponent } from '..';
import { renderHtmlContent } from '../../utills/utills';

const DocumentCard = ({ item, isDeployment }) => {
  const { treeId } = useParams();
  const isActive = treeId === item.id;

  const cssClassName = () => {
    let bgColor = 'bg-light text-dark';
    let svgColor = 'svg-dark';
    let cardTitle =
      item.deploy_status !== 'draft' && item.deploy_status !== ''
        ? `Available on ${item.deploy_status}`
        : 'Needs review';

    if (item.islocked) {
      bgColor = 'bg-document-lock text-white';
      svgColor = 'svg-light';
    }
    if (item.deploy_status === 'production') {
      bgColor = 'bg-success text-white d-flex';
      svgColor = 'svg-light';
    }
    if (item.deploy_status === 'staging') {
      bgColor = 'bg-warning text-dark d-flex';
      svgColor = 'svg-dark';
    }
    return {
      card: `${isDeployment ? '' : 'card'} ${
        item?.deploy_status !== '' && !item?.islocked ? item?.deploy_status : ''
      } card-document flex-fill${isActive ? ' active' : ''} ${
        item.islocked ? ' locked' : ''
      } ${item.isdeleted ? ' deleted' : ''}`,
      cardTitle,
      roundedCircle: `rounded-circle d-flex align-items-center ${svgColor} justify-content-center ${bgColor}`,
      badge: `badge text-capitalize ${bgColor}`,
    };
  };

  const OverlayTriggerMsg = ({ children, message = '' }) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={GETooltip(
          item.islocked
            ? { msg: `${message} Locked` }
            : item.doc_id === 1
            ? { msg: `${message} Decision Tree` }
            : { msg: `${message} Model List` }
        )}
      >
        {children}
      </OverlayTrigger>
    );
  };

  return (
    <div className={cssClassName().card} title={cssClassName().cardTitle}>
      <div className="card-body">
        <div className="d-flex justify-content-start align-items-center mb-2">
          <div className="icon-wrapper flex-1">
            <div className={cssClassName().roundedCircle}>
              <OverlayTriggerMsg>
                {item.doc_id === 1 ? (
                  <SVGComponent.DecisionTree />
                ) : (
                  <SVGComponent.ModelList />
                )}
              </OverlayTriggerMsg>
            </div>
          </div>
          <OverlayTrigger
            placement="top"
            overlay={GETooltip({
              msg: item.name,
            })}
          >
            <h5 className="mb-0 px-2 text-truncate card-title">{item.name}</h5>
          </OverlayTrigger>
          {isActive &&
            (item.islocked ? (
              <span className="svg-danger">
                <SVGComponent.Lock />
              </span>
            ) : (
              <i
                className="fas fa-external-link ml-3"
                data-toggle="tooltip"
                data-placement="top"
                title="Opened"
              ></i>
            ))}
        </div>
        <div className="card-text d-flex justify-content-start align-items-center px-2">
          {!_isEmpty(item.notes?.trim()) && (
            <div className="fs-smaller">
              <span
                className="flex-fill text-desc"
                dangerouslySetInnerHTML={renderHtmlContent(item.notes?.trim())}
              />
            </div>
          )}
        </div>
        <div className="text-muted fw-lighter fs-small right d-flex justify-content-start align-items-center px-2">
          {item?.updated_at ? (
            <div className="text-truncate">
              <i className="fa-solid fa-edit me-1" title="Updated at"></i>{' '}
              <DateFormatter date={new Date(item.updated_at)} />{' '}
              
              {item.updated_by}
            </div>
          ) : (
            <div className="text-truncate">
              <i className="fa-solid fa-pencil me-1" title="Created at"></i>{' '}
              <DateFormatter date={new Date(item.created_at)} />
            </div>
          )}
          <br />
        </div>
        <div className="mt-4 px-2">
          <div className="d-flex justify-content-start align-items-center">
            <span className="badge bg-dark text-white fw-lighter me-2">
              {item?.conf_name || 'Uncategorized'}
            </span>

            <OverlayTriggerMsg message="Deployment status for">
              <span className={`${cssClassName().badge} fw-lighter`}>
                {item.deploy_status || 'Draft'}
              </span>
            </OverlayTriggerMsg>
          </div>
          {item.islocked && (
            <div className="mt-2 d-flex justify-content-start align-items-center">
              <OverlayTriggerMsg message={item.locked_by}>
                <div className="locked-by text-truncate fs-small">
                  <SVGComponent.Lock /> by: {item.locked_by}
                </div>
              </OverlayTriggerMsg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;
