import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useDownloadModelList = ({ prdLn, treeId }) => {
  const downloadModelList = async () => {
    const baseUrl = `${process.env.REACT_APP_API_ORIGIN}/v1/api/model_list/${prdLn}/${treeId}`;
    const data = await axios.get(baseUrl, { headers });
    return data?.data;
  };

  return useQuery({
    queryKey: ["modelList", prdLn, treeId],
    queryFn: downloadModelList,
    enabled: !!prdLn && !!treeId,
    refetchOnWindowFocus: false,

    retry: 3, // Retry the query up to 3 times in case of failure
    staleTime: 30000, // 30 seconds
    cacheTime: 5 * 60 * 1000, // 5 minutes

    onError: (error) => {
      console.error("Error fetching questions:", error);
    },
  });
};
export default useDownloadModelList;
