import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { DeploymentListComponent, Loading } from "../components";
import { useAlert } from "../context/AlertContext";
import useGetEquipments from "../hooks/getEquipments";
import useGetQuestions from "../hooks/getQuestions";
import useUpdateTreeDataStage from "../hooks/updateTreeDataStage";
import { useModal } from "../context/ModalContext";
import { common } from "../utills/static-text";

const Deployment = () => {
  const showAlert = useAlert();
  const documentList = useGetQuestions();
  const filterconfdata = useGetEquipments();
  const updateTreeDataStage = useUpdateTreeDataStage();

  function addCategoryName(source, target) {
    const sourceMap = new Map();
    source?.forEach((item) => {
      sourceMap.set(item.prd_ln, item.name);
    });

    target?.forEach((item) => {
      if (sourceMap.has(item.prd_ln)) {
        item.categ_name = sourceMap.get(item.prd_ln);
      }
    });
    return target;
  }

  const data = addCategoryName(filterconfdata?.data, documentList?.data);
  const [selectedItems, setSelectedItems] = useState({
    draft: [],
    staging: [],
    production: [],
  });

  const handleCheckboxChange = (deployStatus, item) => {
    setSelectedItems((prevState) => {
      const updatedItems = prevState[deployStatus].some((i) => i.id === item.id)
        ? prevState[deployStatus].filter((i) => i.id !== item.id)
        : [...prevState[deployStatus], item];
      return { ...prevState, [deployStatus]: updatedItems };
    });
  };

  const handleSubmit = (deployStatus) => {
    try {
      const envMap = {
        draft: "staging",
        staging: "production",
        production: "draft",
      };
      updateTreeDataStage.mutateAsync(
        {
          body: selectedItems[deployStatus],
          stage: envMap[deployStatus],
        },
        {
          onSuccess: () => {
            documentList.refetch();
            showAlert(
              "Successfully moved to " + submitBtnTxt(deployStatus),
              "success"
            );
          },
          onError: (error) => {
            showAlert(error.response.data.message, "danger");
          },
        }
      );
      showModal(null);
    } catch (e) {
      console.error(e);
    }
  };

  const submitBtnTxt = (status) => {
    switch (status) {
      case "draft":
        return "staging";
      case "staging":
        return "production";
      case "production":
        return "";
      default:
        return "";
    }
  };

  // Sort data by prd_ln
  const sortedData = data?.sort((a, b) => {
    if (a.prd_ln < b.prd_ln) return -1;
    if (a.prd_ln > b.prd_ln) return 1;
    return 0;
  });

  const showModal = useModal();

  return (
    <div className="wrapper">
      <div className="container mx-auto mx-5 p-5">
        <Container>
          {documentList.isLoading ? (
            <Loading isIconOnly={true} />
          ) : (
            <Row>
              {["draft", "staging", "production"].map((status) => {
                // Calculate the count of items for the current status
                const itemCount =
                  sortedData?.filter((item) => item.deploy_status === status)
                    .length || 0;

                return (
                  <Col key={status}>
                    <h3 className="d-flex align-items-center gap-2">
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                      <span className="badge bg-secondary fs-6 p-2">
                        {itemCount}
                      </span>
                    </h3>

                    <DeploymentListComponent
                      items={sortedData?.filter(
                        (item) => item.deploy_status === status
                      )}
                      deployStatus={status}
                      onCheckboxChange={handleCheckboxChange}
                    />
                    {submitBtnTxt(status) !== "" && (
                      <Button
                        className="mt-2 mb-4 w-100"
                        variant="primary"
                        onClick={() => {
                          showModal({
                            title: "Are you sure?",
                            body: `Are you sure you want to move to ${submitBtnTxt(
                              status
                            )}?`,
                            footer: (
                              <div>
                                <Button
                                  variant="primary"
                                  className="me-2"
                                  onClick={() => showModal(null)}
                                >
                                  {common.btn_cancel}
                                </Button>
                                <Button
                                  variant="primary"
                                  className="me-2"
                                  onClick={() => handleSubmit(status)}
                                >
                                  {common.btn_ok}
                                </Button>
                              </div>
                            ),
                          });
                        }}
                        disabled={
                          updateTreeDataStage.isPending ||
                          sortedData.length === 0
                        }
                      >
                        Move to {submitBtnTxt(status)}
                      </Button>
                    )}
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Deployment;
