import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { DateFormatter } from "..";

const ConfigurationCard = ({ item }) => {
  return (
    <div className="card flex-row shadow-sm my-3">
      <div className="card-body">
        <h4 className="card-title h5 h4-sm text-primary position-relative mb-3">
          <i className="fas fa-layer-group" aria-hidden="true"></i>{" "}
          <NavLink
            to={`/configuration/${item.prd_ln}/${item.id}`}
            key={`view-mode-${item.id}`}
            className={({ isActive }) =>
              isActive
                ? "text-decoration-none display-block"
                : "text-decoration-none display-block"
            }
            title={`Edit ${item.name}`}
          >
            <span className="fw-bold">{item.name}</span>
          </NavLink>
          <span className="float-end">
            <NavLink
              to={`/configuration/${item.prd_ln}/${item.id}/edit`}
              key={item.id}
              className={({ isActive }) =>
                isActive
                  ? "text-decoration-none display-block btn btn-primary"
                  : "text-decoration-none display-block btn btn-outline-primary"
              }
              title={`Edit ${item.name}`}
            >
              <i className="fa-solid fa-edit"></i>
            </NavLink>
          </span>
        </h4>
        <p className="mb-2 text-muted fs-smaller border-bottom">
          <i className="fa-solid fa-pencil" title="Created at"></i>{" "}
          <span title="Created at">
            <DateFormatter date={new Date(item.created_at)} />
          </span>
          {item?.updated_at && (
            <Fragment>
              <span className="me-2"> </span>
              <i className="fa-solid fa-edit" title="Updated at"></i>{" "}
              <span title="Updated at">
                <DateFormatter date={new Date(item.updated_at)} />
              </span>
            </Fragment>
          )}
        </p>

        <p className="fst-italic fw-lighter text-muted text-capitaliz">
          {item.description ||
            `The description for the ${item?.name} goes here...`}
        </p>

        <div className="text-right">
          <i className="fa-solid fa-sitemap" title="Decision Tree Count"></i>{" "}
          <span title="Decision Tree Count" className="me-3">
            {" "}
            {item?.decision_tree_count}
          </span>
          <i className="fa-solid fa-grip" title="Models Tree Count"></i>{" "}
          <span title="Models Tree Count"> {item?.models_list_count}</span>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationCard;
