import { useQueryClient } from "@tanstack/react-query";
import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, TagInput, TextEditor } from "../../components";
import { useAlert } from "../../context/AlertContext";
import useUpdateModelProperties from "../../hooks/updateModeListProperties";
import { updateTreeNodeData } from "../../store/actions/authoring.actions";
import { deployTreeData } from "../../store/actions/authoring.actions";
import useGetQuestions from "../../hooks/getQuestions";
import { useModal } from "../../context/ModalContext";
import { common } from "../../utills/static-text";

const DocumentProperty = ({ documentProperty, isDocumentLocked }) => {
  const { prdLn = "", treeId = "" } = useParams();
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [displayConfig, setDisplayConfig] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    notes: "",
    tags: [],
    parts: [],
  });
  const [errors, setErrors] = useState({});
  const updateModelListProperties = useUpdateModelProperties();
  const documentList = useGetQuestions();
  const showModal = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    const quesType = documentProperty?.ques_type;
    const displayConfig = {
      name: documentProperty?.doc_id === 2 ? false : true,
      notes: quesType !== "option",
      parts: quesType === "solution",
      tags: documentProperty?.doc_id === 2 ? false : true,
      file: false,
    };
    setDisplayConfig(displayConfig);
    if (documentProperty !== null && documentProperty !== undefined) {
      setFormValues({
        ...documentProperty,
        tags:
          documentProperty.tags !== null &&
          documentProperty.tags !== undefined &&
          documentProperty.tags.trim(),
        parts:
          documentProperty.parts !== null &&
          documentProperty.parts !== undefined &&
          documentProperty.parts.trim(),
      });
    }
    // if (isDocumentLocked) {
    //   showAlert("Unlock the document to edit the information!", "warning");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentProperty]);

  const putDocumentDraft = () => {
    try {
      dispatch(
        deployTreeData({
          query: { env: "draft", prd_ln: prdLn, id: treeId },
        })
      );
      documentList.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseModal = () => {
    showModal(null);
    // navigate('/authoring');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    showModal({
      title: "Are you sure?",
      body: "The document will be move to stage for the review!.",
      footer: (
        <div>
          <Button
            variant="primary-outline"
            className="me-2"
            onClick={() => handleCloseModal()}
          >
            {common.btn_cancel}
          </Button>
          <Button variant="primary" className="me-2" onClick={formSubmit}>
            {common.btn_ok}
          </Button>
        </div>
      ),
    });
  };

  const formSubmit = () => {
    // event.preventDefault();
    if (formValues.doc_id === 2) {
      updateModelListProperties.mutateAsync(
        {
          prd_ln: prdLn,
          id: formValues.id,
          notes: formValues.notes,
        },
        {
          onSuccess: () => {
            // Move to draft
            putDocumentDraft();

            // queryClient.invalidateQueries({
            //   queryKey: [''],
            // }); // TODO: Change this query key
            showAlert("Notes is updated!", "success");
          },
          onError: (error) => {
            showAlert(error.response.data.message, "danger");
          },
        }
      );
    } else {
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setIsLoading(true);
      dispatch(
        updateTreeNodeData({
          query: {
            prd_ln: prdLn,
            id: formValues.id,
          },
          body: {
            id: formValues.id,
            ques_type: formValues.ques_type,
            ques_raw_content: formValues.raw_content,
            raw_content: formValues.raw_content,
            start_id: formValues.id,
            notes: formValues.notes,
            parts: formValues.parts,
            tags: formValues.tags,
          },
          method: "PUT",
        })
      ).then(() => {
        // Move to draft
        putDocumentDraft();

        queryClient.invalidateQueries({
          queryKey: ["treeData", prdLn],
        });

        setIsLoading(false);
        showAlert("Node is updated!", "success");
      });
    }

    handleCloseModal();
  };

  const validateForm = () => {
    let errors = {};
    if (!formValues.raw_content) {
      errors.raw_content = "Please enter document name";
    }
    return errors;
  };

  const handleInputChange = (key, value) => {
    if (
      key === "tags" &&
      value !== "" &&
      value !== null &&
      value !== undefined &&
      value !== false &&
      value !== true
    ) {
      setFormValues({
        ...formValues,
        [key]: value ? value : [],
      });
    } else if (
      key === "parts" &&
      value !== "" &&
      value !== null &&
      value !== undefined &&
      value !== false &&
      value !== true
    ) {
      setFormValues({
        ...formValues,
        [key]: value ? value : [],
      });
    } else {
      setFormValues({
        ...formValues,
        [key]: value,
      });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {isLoading ? (
          <Loading isIconOnly={true} />
        ) : (
          <Fragment>
            <h5 className="mt-2 mb-3 text-capitalize">
              {documentProperty?.ques_type}
            </h5>

            {formValues && (
              <Form onSubmit={handleSubmit}>
                {displayConfig.name && (
                  <Form.Group className="mb-3">
                    <TextEditor
                      value={formValues.raw_content}
                      onChange={(content) =>
                        handleInputChange("raw_content", content)
                      }
                      errors={errors}
                      disabled={!isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.notes && (
                  <Form.Group className="mb-3">
                    <Form.Label>Notes</Form.Label>
                    <TextEditor
                      value={formValues.notes}
                      onChange={(content) =>
                        handleInputChange("notes", content)
                      }
                      errors={errors}
                      disabled={
                        !isDocumentLocked && documentProperty?.doc_id === 1
                      }
                    />
                  </Form.Group>
                )}
                {displayConfig.tags && (
                  <Form.Group className="mb-3">
                    <Form.Label>Tags</Form.Label>
                    <TagInput
                      value={!_isEmpty(formValues.tags) ? formValues?.tags : ""}
                      onChange={(content) => handleInputChange("tags", content)}
                      disabled={!isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.parts && (
                  <Form.Group className="mb-3">
                    <Form.Label>Parts</Form.Label>
                    <TagInput
                      value={
                        !_isEmpty(formValues?.parts) ? formValues?.parts : ""
                      }
                      onChange={(content) =>
                        handleInputChange("parts", content)
                      }
                      disabled={!isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.file && (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control type="file" disabled={true} />
                  </Form.Group>
                )}
                <div className="row">
                  <div className="col-12 mt-3 text-end">
                    <button
                      className="btn btn-muted me-2"
                      disabled={
                        !isDocumentLocked && documentProperty?.doc_id === 1
                      }
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        !isDocumentLocked && documentProperty?.doc_id === 1
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DocumentProperty;
