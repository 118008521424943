import React, { useState, useEffect, Fragment } from "react";
import { Form, Badge } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";

const TagInput = ({ value, onChange, disabled }) => {
  const [tagValues, setTagValues] = useState([]);
  const [inputTagValue, setInputTagValue] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Check for value change to avoid infinite loop
    if (value !== tagValues.join(",") || _isEmpty(tagValues)) {
      const newTags = _isEmpty(value)
        ? []
        : typeof value === "object"
        ? value.map((tag) => tag.trim())
        : value
            .replace(/[^\w,]/g, "")
            .split(",")
            .map((tag) => tag.trim());
      setTagValues(newTags);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleRemove = (index) => {
    if(disabled) return;
    const newValues = [...tagValues];
    newValues.splice(index, 1);
    setTagValues(newValues);
    onChange(newValues.join(","));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputTagValue.trim() !== "") {
        // Uncomment and implement validation logic if needed
        // if (!validateTag(inputTagValue.trim())) return;

        if (!tagValues.includes(inputTagValue.trim())) {
          setTagValues([...tagValues, inputTagValue.trim()]);
          setInputTagValue("");
          onChange([...tagValues, inputTagValue.trim()].join(","));
        } else {
          setErrors({ tag: `${inputTagValue.trim()} is a duplicate tag` });
        }
      }
    }
  };

  // const validateTag = (value) => {
  //   if (!value) {
  //     setErrors({ tag: "Tag value cannot be empty" });
  //     return false;
  //   }
  //   if (tagValues.includes(value)) {
  //     setErrors({ tag: `${inputTagValue.trim()} is a duplicate tag` });
  //     return false;
  //   }
  //   return true;
  // };

  const handleInputChange = (e) => {
    const value = e.target.value;

    setInputTagValue(value);
    // Clear errors when input value changes
    if (errors.tag) {
      setErrors({});
    }
  };

  return (
    <>
      <Form.Control
        type="text"
        value={inputTagValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Press Enter to add value"
        disabled={disabled}
      />
      {errors.tag && (
        <div className="text-danger mt-1 fs-small ">{errors.tag}</div>
      )}
      <div className="pt-2">
        {tagValues && (
          <Fragment>
            {tagValues?.map((value, index) => (
              <Badge
                className="fw-lighter"
                key={index}
                pill
                variant="primary"
                style={{ margin: "2px", cursor: "pointer" }}
                onClick={() => handleRemove(index)}
              >
                {value} <span style={{ marginLeft: "5px" }}>x</span>
              </Badge>
            ))}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default TagInput;
