const EmptyCard = ({ title, subtitle = "", body = " ", className = "" }) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-header">
        <h5 className="card-title">{title}</h5>
        <h6 className="card-subtitle text-muted">{subtitle}</h6>
      </div>
      <div className="card-body">{body}</div>
    </div>
  );
};

export default EmptyCard;
