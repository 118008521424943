import { useQueryClient } from '@tanstack/react-query';
import { ContextMenuTrigger } from 'rctx-contextmenu';
import React, { useMemo, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import { useModal } from '../../context/ModalContext';
import useGetQuestionHeaderData from '../../hooks/getQuestionHeaderData';
import {
  addNodeData,
  copyNodeAction,
  copyNodeData,
  deleteNodeAction,
} from '../../store/actions/authoring.actions';
import { common } from '../../utills/static-text';
import { renderHtmlContent } from '../../utills/utills';
import ContextMenuComponent from './ContextMenuComponent';
import ModalComponent from './Modal';
import NodeIcon from './NodeIcon';

const TreeNode = ({
  node,
  documentProperty,
  setDocumentProperty,
  isDocumentLocked,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { prdLn, startId, treeId, type, nodeId, nodeQuesId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const [show, setShow] = useState(false);
  const [formAction, setFormAction] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [inputOptionText, setInputOptionText] = useState('');
  const [inputQuestionText, setInputQuestionText] = useState('');
  const [inputSolutionText, setInputSolutionText] = useState('');
  const documentData = useGetQuestionHeaderData({
    prd_ln: prdLn,
    id: treeId,
  });
  const { profile } = useSelector((state) => state.user);
  const { copyNode, removeNode = null } = useSelector(
    (state) => state.authoring
  );
  const showModal = useModal();
  const showAlert = useAlert();
  const handleSubmit = () => {
    const errors = {};
    let hasError = false;

    // Validate inputOptionText
    if (!inputOptionText.trim()) {
      errors.inputOptionText = 'Option text is required.';
      hasError = true;
    }

    // Validate inputQuestionText or inputSolutionText based on the formAction
    if (formAction === 'add_child_question') {
      if (!inputQuestionText.trim()) {
        errors.inputQuestionText = 'Question text is required.';
        hasError = true;
      }
    } else {
      if (!inputSolutionText.trim()) {
        errors.inputSolutionText = 'Solution text is required.';
        hasError = true;
      }
    }

    // Update formErrors state with validation errors
    setFormErrors(errors);

    // If there are errors, stop form submission
    if (hasError) {
      return;
    }

    const body =
      formAction === 'add_child_question'
        ? {
            type: 'question/option',
            ques_raw_content: inputQuestionText,
            option_raw_content: inputOptionText,
            start_id: node.m_id,
          }
        : {
            type: 'solution/option',
            ques_raw_content: inputSolutionText, // TODO: @ravige the key is wrong
            option_raw_content: inputOptionText,
            start_id: node.m_id,
          };

    dispatch(
      addNodeData({
        query: { prd_ln: prdLn, id: treeId },
        body,
      })
    ).then(() => {
      queryClient.invalidateQueries({ queryKey: ['treeData', prdLn] });
    });
    resetForm();
  };

  const enabled = useMemo(() => {
    if (isDocumentLocked) {
      return (
        documentData?.data?.[0]?.locked_by?.toLowerCase() !==
        profile?.email?.toLowerCase()
      );
    }
    return true;
  }, [isDocumentLocked, documentData, profile]);

  const memoizedCtxMenu = useMemo(() => {
    const ctxMenu = [
      ...(node.ques_type === 'question'
        ? [
            {
              item: 'Add Child Question',
              action: 'add_child_question',
              disabled: enabled,
            },
            {
              item: 'Add Child Solution',
              action: 'add_child_solution',
              disabled: enabled,
            },
          ]
        : []),
      { item: 'Edit', action: 'edit', disabled: enabled },
      ...(node.ques_type === 'question' || node.ques_type === 'solution'
        ? [
            {
              item: 'Copy',
              action: 'copy',
              disabled: enabled || node?.m_id === 1,
            },
          ]
        : []),
      ...(node.ques_type === 'question'
        ? [{ item: 'Paste', action: 'paste', disabled: enabled }]
        : []),
      { item: 'Delete', action: 'delete', disabled: enabled },
      ...(node.ques_type === 'question'
        ? [
            {
              item: 'Copy Node To Link',
              action: 'copyLink',
              disabled: enabled,
            },
            { item: 'Link Node', action: 'link', disabled: enabled },
          ]
        : []),
    ];
    return ctxMenu;
  }, [node, enabled]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    setDocumentProperty(node);
  };

  const handleContextMenuAction = (action) => {
    // Handle context menu actions
    let successMessage = '';
    switch (action) {
      case 'add_child_question':
      case 'add_child_solution':
        setFormAction(action);
        setShow(true);
        break;

      case 'copy':
        dispatch(copyNodeAction(node));
        successMessage = `${node.ques_type} is copied!`;
        showAlert(successMessage, 'success');
        break;

      // case "edit":
      //   // toggleDocumentLock();
      //   successMessage = `${node.ques_type} is edited!`;
      //   break;

      case 'paste':
        if (node.id === copyNode.id) {
          showAlert("Can't paste in the same node.");
        } else {
          dispatch(
            copyNodeData({
              query: { prd_ln: prdLn, id: treeId },
              body: {
                start_id: node.m_id,
                raw_content: copyNode.raw_content,
              },
            })
          ).then(() => {
            queryClient.invalidateQueries({ queryKey: ['treeData', prdLn] });
          });
          dispatch(copyNodeAction({}));
          // successMessage = `${node.ques_type} is pasted!`;
        }
        break;

      case 'delete':
        showModal({
          title: 'Are you sure?',
          body: 'Are you sure you want to delete node?',
          footer: (
            <div>
              <Button
                variant="primary"
                className="me-2"
                onClick={handleCloseDeleteModal}
              >
                {common.btn_cancel}
              </Button>
              <Button
                variant="primary"
                className="me-2"
                onClick={handleDeleteNode}
              >
                {common.btn_ok}
              </Button>
            </div>
          ),
        });
        break;

      // case "copyLink":
      //   dispatch(linkNodeAction(node));
      //   successMessage = `${node.ques_type} is copied!`;
      //   break;

      // case "link":
      //   if (node.id === linkNode.id) {
      //     showAlert("Can't link in the same node.");
      //   } else {
      //     dispatch(
      //       linkNodeData({
      //         query: { prd_ln: prdLn, id: treeId },
      //         body: { start_id: node.id, end_id: linkNode.id },
      //       })
      //     );
      //     dispatch(linkNodeAction({}));
      //     successMessage = `${node.ques_type} is linked!`;
      //   }
      //   break;

      default:
        break;
    }

    // if (successMessage) {
    //   showAlert(successMessage, "success");
    // }
  };

  const resetForm = () => {
    setInputOptionText('');
    setInputQuestionText('');
    setInputSolutionText('');
    setFormAction('');
    setShow(false);
  };
  const handleCloseDeleteModal = () => {
    showModal(null);
  };
  const handleDeleteNode = () => {
    // Doomsday for node
    dispatch(
      deleteNodeAction({
        query: {
          ques_type: node.ques_type,
          id: node.id,
        },
      })
    ).then(() => {
      queryClient.invalidateQueries({ queryKey: ['treeData', prdLn] });
      showAlert(removeNode ? removeNode?.message : 'node is deleted!');
    });
    showModal(null);
  };

  return (
    <div className="treeview tree-node">
      <ContextMenuTrigger
        id={`context-menu-${node.id}`}
        // className={`context-menu-${node.id}`}
      >
        {/* {console.log("node ::", node)} */}
        <Link
          onClick={handleExpand}
          aria-controls={`collapse-item-${node.id}`}
          aria-expanded={isExpanded}
          to={`/authoring/tree/${prdLn}/${startId}/${treeId}`}
          className={`${node.ques_type} node-toggle ${
            nodeId === node.id.toString() &&
            type === node.ques_type &&
            nodeQuesId === node.id
              ? ' active'
              : ''
          }`}
        >
          <NodeIcon nodeType={node.ques_type} />
          <span
            className="text-desc"
            dangerouslySetInnerHTML={renderHtmlContent(node.raw_content)}
          />
          {/* <span className="text-desc">{node.raw_content}</span> */}
        </Link>
      </ContextMenuTrigger>

      <ContextMenuComponent
        id={`context-menu-${node.id}`}
        ctxMenu={memoizedCtxMenu}
        onContextMenuAction={handleContextMenuAction}
      />

      <Collapse id={`collapse-item-${node.id}`} in={isExpanded}>
        <ul className="child-nodes" style={{ listStyleType: 'none' }}>
          {node.children &&
            node.children.map((childNode, index) => (
              <li key={`child-node-${prdLn}-${startId}-${treeId}-${index}`}>
                {isExpanded && (
                  <TreeNode
                    node={childNode}
                    documentProperty={documentProperty}
                    setDocumentProperty={setDocumentProperty}
                    isDocumentLocked={isDocumentLocked}
                  />
                )}
              </li>
            ))}
        </ul>
      </Collapse>

      <ModalComponent
        show={show}
        handleCloseModal={() => setShow(false)}
        handleFormSubmit={handleSubmit}
        formAction={formAction}
        inputOptionText={inputOptionText}
        setInputOptionText={setInputOptionText}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        inputQuestionText={inputQuestionText}
        setInputQuestionText={setInputQuestionText}
        inputSolutionText={inputSolutionText}
        setInputSolutionText={setInputSolutionText}
      />
    </div>
  );
};
export default TreeNode;
