import { produce } from "immer";
import ACTIONS from "../actions/types.actions";
import {updateNode } from "../../utills/utills";
const initialState = {
  documentData: null,
  documentList: [],
  equipmentListData: [],
  createDocument: null,
  filterByConf: [],
  treeData: null,
  newDocument: null,
};

const authoring = produce((draft = initialState, action) => {
  switch (action.type) {
    case ACTIONS.AUTHORING_GET_LIST:
      draft.documentList = action.data;
      draft.equipmentListData = action.data;
      return draft;
    case ACTIONS.AUTHORING_CONF_FILTER:
      draft.filterByConf = action.data;
      return draft;
    case ACTIONS.AUTHORING_GET_DOCUMENT:
      draft.documentData = action.data;
      return draft;
    case ACTIONS.AUTHORING_GET_TREEDATA:
      draft.treeData = action.data;
      return draft;
    case ACTIONS.AUTHORING_NEW_DOCUMENT_POST:
      draft.newDocument = action.data;
      return draft;
    case ACTIONS.TREENODE_DATA_PUT_POST:
      if (draft.treeData && Array.isArray(draft.treeData)) {
        draft.treeData = updateNode(draft.treeData, action.data);
      }
      return draft;
    case ACTIONS.COPY_NODE:
      draft.copyNode = action.data;
      return draft;
    case ACTIONS.LINK_NODE:
      draft.linkNode = action.data;
      return draft;
    case ACTIONS.TREENODE_ADD_POST:
      draft.addNode = action.data;
      return draft;
    case action.TREENODE_REMOVE:
      draft.removeNode = action.data;
      return draft;
    case ACTIONS.SEARCH_TEXT_POST:
        draft.searchTextData = action.data;
        return draft;
    case ACTIONS.EQUIPMENTS_DATA_PUT_POST:
        draft.createDocument = action.data;
        return draft;
    default:
      return draft; // Return draft for unhandled actions
  }
}, initialState);

export default authoring;
