import { Configuration } from '../../services/api.service';
import { throwError, to } from '../../utills/utills';
import appInitialState from '../app-state/app-initial.state';
import ACTIONS from './types.actions';

/**
 * Equipment List Data
 * @returns { type: EQUIPMENTS_DATA_GET_LIST, [{...equipment}] }
 */
export function equipmentListData(data) {
  return { type: ACTIONS.EQUIPMENTS_DATA_GET_LIST, data };
}

/**
 * Equipment List Data Dispacher
 */
export function getEquipmentListData() {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Configuration.getEquipmentList());
      if (err) throwError(err);
      dispatch(equipmentListData(res.data));
    } catch (error) {
      throwError(error);
    }
  };
}

/**
 * Equipment item
 * @returns { type: EQUIPMENTS_DATA_GET_ID, ...equipment }
 */

export function equipmentData(data) {
  return { type: ACTIONS.EQUIPMENTS_DATA_GET_ID, data };
}

/**
 * Equipment List Data Dispacher
 */
export function getEquipmentData(query) {
  if (query && query?.confId === 'new') {
    return async (dispatch) => {
      dispatch(equipmentData(appInitialState?.configuration?.equipmentData));
    };
  } else {
    return async (dispatch) => {
      try {
        const [err, res] = await to(Configuration.getEquipmentById(query));
        if (err) throwError(err);
        dispatch(equipmentData(res));
      } catch (error) {
        throwError(error);
      }
    };
  }
}

/**
 * Equipment item PUT
 * @returns { type: EQUIPMENTS_DATA_PUT_ID, ...equipment }
 */

export function updateEquipment(data) {
  return { type: ACTIONS.EQUIPMENTS_DATA_PUT_POST, data };
}

/**
 * Equipment List Data Dispacher
 */
export function updateEquipmentData(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Configuration.updateEquipmentById(options));
      if (err) throwError(err);
      dispatch(updateEquipment(res));
    } catch (error) {
      throwError(error);
    }
  };
}

/**
 * Add problem category
 */
export function addProblem(options) {
  return async (dispatch) => {
    try {
      const [err] = await to(Configuration.addProblem(options));
      if (err) throwError(err);
    } catch (error) {
      throwError(error);
    }
  };
}

/**
 * Search problem category
 */
export function searchProblemData(data) {
  return { type: ACTIONS.AUTHORING_PROBLEM_SEARCH, data };
}

export function searchProblem(options) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(Configuration.searchProblem(options));
      if (err) throwError(err);
      dispatch(searchProblemData(res.data));
    } catch (error) {
      throwError(error);
    }
  };
}

/**
 * Add problem category
 */
export function editProblem(options) {
  return async (dispatch) => {
    try {
      const [err] = await to(Configuration.editProblem(options));
      if (err) throwError(err);
    } catch (error) {
      throwError(error);
    }
  };
}

/**
 * Delete problem category
 */
export function deleteProblem(query) {
  return async (dispatch) => {
    try {
      const [err] = await to(Configuration.deleteProblem(query));
      if (err) throwError(err);
    } catch (error) {
      throwError(error);
    }
  };
}
