import React from "react";
import { SVGComponent } from "..";

const NodeIcon = ({ nodeType }) => {
  switch (nodeType) {
    case "question":
      return (
        <span className="svg-question me-2">
          <SVGComponent.Question />
        </span>
      );
    case "solution":
      return (
        <span className="svg-success me-2">
          <SVGComponent.Solution />
        </span>
      );
    default:
      return (
        <span className="svg-dark me-2">
          <SVGComponent.Option />
        </span>
      );
  }
};

export default NodeIcon;
