import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useCreateModelListData = () => {
  const createModelList = async (data) => {
    const baseURL = `${process.env.REACT_APP_API_ORIGIN}/v1/api/model_list/${data?.prd_ln}`;
    return await axios.post(baseURL, data, { headers });
  };
  return useMutation({
    mutationFn: createModelList,
  });
};

export default useCreateModelListData;
