import React, { Fragment } from "react";
import { configuration } from "../../utills/static-text";
import FormEquipment from "./FormEquipment";
import { Tab, Tabs } from "react-bootstrap";
import {
  ConfigurationView,
  FormProblemCategories,
  Loading,
} from "../../components";
import useGetEquipmentData from "../../hooks/getEquipmentData";

const ConfigurationDetails = ({ confId, prdLn, mode }) => {
  const equipmentData = useGetEquipmentData({ prdLn, confId });
  return (
    <div className="card sticky-top conf-property">
      <div className="card-header">
        <h5 className="card-title">
          {equipmentData.name || configuration?.configuration_card?.title}
        </h5>
        <h6 className="card-subtitle fst-italic fw-lighter text-muted text-capitaliz text-center">
          {equipmentData?.description ||
            configuration?.configuration_card?.subtitle}
        </h6>
      </div>
      <div className="card-body">
        {equipmentData.isLoading ? (
          <Loading isIconOnly={true} />
        ) : (
          <Fragment>
            {mode === "view" && <ConfigurationView {...equipmentData?.data} />}
            {mode === "new" && (
              <Tabs
                defaultActiveKey="property"
                id="uncontrolled-tab"
                className="mb-3"
              >
                <Tab eventKey="property" title="Property">
                  <FormEquipment equipmentData={equipmentData} />
                </Tab>
                <Tab eventKey="problemcategories" title="Problem Categories">
                  <FormProblemCategories
                    equipmentData={equipmentData}
                    prdLn={prdLn}
                  />
                </Tab>
              </Tabs>
            )}
            {mode === "edit" && (
              <Tabs
                defaultActiveKey="property"
                id="uncontrolled-tab"
                className="mb-3"
              >
                <Tab eventKey="property" title="Property">
                  <FormEquipment equipmentData={equipmentData} />
                </Tab>
                <Tab eventKey="problemcategories" title="Problem Categories">
                  {equipmentData?.data?.problemCategory && (
                    <FormProblemCategories
                      equipmentData={equipmentData}
                      prdLn={prdLn}
                    />
                  )}
                </Tab>
              </Tabs>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ConfigurationDetails;
