import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const configuration = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.EQUIPMENTS_DATA_GET_LIST:
      draft.equipmentListData = action.data;
      return draft;
    case ACTIONS.EQUIPMENTS_DATA_GET_ID:
      draft.equipmentData = action.data;
      return draft;
    case ACTIONS.AUTHORING_PROBLEM_SEARCH:
      draft.problemSearch = action.data;
      return draft;
    default:
      return draft;
  }
});

export default configuration;
