// src/components/Item.js
import React from 'react';
import { Card, Form, ListGroup } from 'react-bootstrap';
import { DocumentCard } from '..';

const DeploymentListComponent = ({ items, deployStatus, onCheckboxChange }) => {
  return (
    <ListGroup>
      {items.map((item) => (
        <ListGroup.Item key={item.id} className="p-0 mb-1 rounded">
          <Card
            className={`${deployStatus === 'production' ? 'px-0' : 'px-2'} ${
              item.islocked ? 'locked' : deployStatus
            }`}
          >
            <Form.Check
              type="checkbox"
              disabled={item.islocked}
              hidden={deployStatus === 'production'}
              className={`${deployStatus === 'production' ? 'p-0' : ''}`}
              label={
                <div
                  className="d-grid gap-4"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from bubbling up
                    onCheckboxChange(deployStatus, {
                      prd_ln: item.prd_ln,
                      id: item.id,
                    });
                  }}
                >
                  <DocumentCard item={item} isDeployment={true} />
                </div>
              }
              onChange={() =>
                onCheckboxChange(deployStatus, {
                  prd_ln: item.prd_ln,
                  id: item.id,
                })
              }
            />
          </Card>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default DeploymentListComponent;
