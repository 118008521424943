import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const user = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.AUTH_REQUEST:
            draft.profile = action.data;
            return draft;
        case ACTIONS.AUTH_ERROR:
            draft.error = action.error;
            return draft;
        default:
            return draft;
    }
});

export default user;
