import React, { Fragment } from "react";
import { SVGComponent } from "..";

const Loading = ({ isIconOnly }) => {
  return (
    <Fragment>
      {isIconOnly ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <SVGComponent.Loading />
        </div>
      ) : (
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <SVGComponent.Loading />
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Loading;
