import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { common } from '../../utills/static-text';
import GETooltip from '../UI/GETooltip';

const FilterByConf = ({
  getDashboardData,
  activeCaseBase,
  handelSetActive,
}) => {
  const { data } = getDashboardData;
  const filterByConf = data?.filterByConf || [];

  return (
    <div className="row mb-4">
      <div className="col-lg-12">
        <h5 className="text-success">
          <i className="fa-regular fa-file"></i> Available Case base
        </h5>
        {filterByConf?.map((conf) => (
          <span
            className={`badge count position-relative me-3 fw-light p-2 my-3 ${
              activeCaseBase === conf.name ? 'active' : ''
            }`}
            key={conf.name}
          >
            <button
              className="btn btn-link m-0 p-0"
              type="button"
              onClick={handelSetActive} // Ensure the correct case base is set
            >
              {conf.name}
            </button>
            <OverlayTrigger
              placement="top"
              overlay={GETooltip({ msg: common.decision_tree })}
            >
              <span className="bg-warning tree">{conf.tree_count}</span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={GETooltip({ msg: common.model_list })}
            >
              <span className="bg-primary text-white model">
                {conf.models_count}
              </span>
            </OverlayTrigger>
          </span>
        ))}
      </div>
    </div>
  );
};

export default FilterByConf;
