import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
// Validate column titles
const expectedColumnTitles = String(
  process.env.REACT_APP_MODEL_LIST_DOCUMENT_MANDATORY_FIELDS || ''
).split(',');
const UploadCSVInput = ({ setCsvData, csvData }) => {
  const [file, setFile] = useState(null);
  const [isValidCsv, setIsValidCsv] = useState(true);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    function keysToLowerCase(obj) {
      let newObj = {};
      for (let key in obj) {
        newObj[key.toLowerCase()] = obj[key];
      }
      return newObj;
    }

    // Validate CSV file
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const payloadData = [];
        const csvData = event.target.result;
        const lines = csvData.split('\n');
        const columnTitles = lines[0].trim().split(',');
        const isValid = expectedColumnTitles.every((title) =>
          columnTitles.map((t) => t.toLowerCase()).includes(title.toLowerCase())
        );

        setIsValidCsv(isValid);

        if (isValid) {
          // Split the first line to get the keys
          const keys = lines[0].trim().split(',');
          // Process each row after the header
          lines.slice(1).forEach((line, index) => {
            const row = line.trim().split(','); // rows line in csv like: ADW10,41,DISHWASHER,BUILT-IN, ,

            // Create an object from the keys and row values
            const rowData = {};
            keys.forEach((key, i) => {
              rowData[key] = row[i];
            });

            // Check for mandatory columns
            const hasMandatoryData = expectedColumnTitles.every(
              (key) => rowData[key] && rowData[key].trim() !== ''
            );

            // Push the valid row data to payloadData
            if (hasMandatoryData) {
              payloadData.push(keysToLowerCase(rowData));
            }
          });

          setCsvData(payloadData);
        }
      };

      reader.readAsText(selectedFile);
    }
  };
  return (
    <Form.Group className="mb-3">
      <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
      {!isValidCsv && (
        <p className="text-danger">CSV file columns are not valid.</p>
      )}
      {isValidCsv && file && (
        <p className="text-success">
          Selected{' '}
          <em>
            <u>"{file.name}"</u>
          </em>{' '}
          is validatd with columns.
        </p>
      )}
    </Form.Group>
  );
};

export default UploadCSVInput;
