import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useUpdateModelProperties = () => {
  const updateModelProperties = async (data) => {
    const baseURL = `${process.env.REACT_APP_API_ORIGIN}/v1/api/model_list/properties`;
    return await axios.put(baseURL, data, { headers });
  };
  return useMutation({
    mutationFn: updateModelProperties,
  });
};

export default useUpdateModelProperties;
