export const handleDownloadCsv = ({ jsonData, fileName }) => {
  // Function to create a Blob and trigger a download
  const triggerDownload = (data, fileName) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const objectUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = objectUrl;
    anchor.download = fileName;
    anchor.click();

    // Cleanup
    URL.revokeObjectURL(objectUrl);
  };

  // Sample CSV data
  const sampleCsvData =
    'MODEL_NO,PRODUCT_LINE_CODE,PRODUCT_TYPE,PRODUCT_SUBTYPE,MD_TYPE,MD_SUBTYPE';

  // Replace spaces with underscores and convert to lowercase in the filename
  const formattedFileName = (fileName || 'download.csv')
    .replace(/ /g, '_') // Replace spaces with underscores
    .toLowerCase(); // Convert to lowercase

  // Check if JSON data is provided
  if (jsonData && jsonData.length > 0) {
    // Convert keys of all objects to uppercase
    const upperCaseKeysJsonData = jsonData.map((item) =>
      Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key.toUpperCase(), value])
      )
    );

    // Extract keys from the first object to use as CSV headers
    const headers = Object.keys(upperCaseKeysJsonData[0]);

    // Create CSV rows
    const csvRows = upperCaseKeysJsonData.map((row) =>
      headers
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );

    // Combine headers and rows
    const csvString = [headers.join(','), ...csvRows].join('\n');

    // Trigger download with JSON data
    triggerDownload(csvString, formattedFileName);
  } else {
    // Trigger download with sample data
    triggerDownload(sampleCsvData, 'sample_model_list.csv');
  }
};

// Optional: A replacer function to handle special characters
const replacer = (key, value) => (value === null ? '' : value); // Handle null values
