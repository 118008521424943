import { useState } from 'react';
import DocumentListCards from '../components/Dashboard/DocumentListCards';
import FilterByConf from '../components/Dashboard/FilterByConf';
import StageCards from '../components/Dashboard/StageCards';
import TotalCards from '../components/Dashboard/TotalCards';
import useGetDashboardData from '../hooks/getDashboardData';
import { Loading } from '../components';

const Dashboard = () => {
  const [activeCaseBase, setActiveCaseBase] = useState('ALL');
  const getDashboardData = useGetDashboardData(activeCaseBase);
  const handelSetActive = (event) => {
    event.preventDefault();
    if (activeCaseBase !== event.target.textContent) {
      setActiveCaseBase(event.target.textContent);
    } else {
      setActiveCaseBase('ALL');
    }
  };
  return (
    <div
      className={
        activeCaseBase === 'ALL'
          ? 'wrapper dashboard'
          : 'wrapper dashboard filtered'
      }
    >
      <div className="container mx-auto mx-5 p-5">
        {getDashboardData.isLoading ? (
          <Loading isIconOnly />
        ) : getDashboardData.isError ? (
          <h1>Something went wrong</h1> // TODO: Handle error
        ) : (
          <>
            <FilterByConf
              getDashboardData={getDashboardData}
              activeCaseBase={activeCaseBase}
              handelSetActive={handelSetActive}
            />
            <StageCards
              getDashboardData={getDashboardData}
              activeCaseBase={activeCaseBase}
            />
            <TotalCards getDashboardData={getDashboardData} />
            <DocumentListCards
              getDashboardData={getDashboardData}
              activeCaseBase={activeCaseBase}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default Dashboard;
