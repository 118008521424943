import React, { Fragment } from 'react';
import { ContextMenu, ContextMenuItem } from 'rctx-contextmenu';

const ContextMenuComponent = ({ id, ctxMenu, onContextMenuAction }) => (
  <ContextMenu id={id}>
    {ctxMenu.map((ctx, index) => (
      <Fragment key={`ctx-${id}`}>
        <ContextMenuItem
          onClick={() => onContextMenuAction(ctx.action)}
          className={ctx.hadBorderBottom ? 'border-bottom' : ''}
          disabled={ctx.disabled}
        >
          {ctx.item}
        </ContextMenuItem>
      </Fragment>
    ))}
  </ContextMenu>
);

export default ContextMenuComponent;
