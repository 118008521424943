import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useGetTreeData = ({ prd_ln }) => {
  const getTreeData = async () => {
    const baseURL = `${process.env.REACT_APP_API_ORIGIN}/v1/api/treedata/${prd_ln}`;
    return await axios.get(baseURL, { headers });
  };

  return useQuery({
    queryFn: getTreeData,
    queryKey: ["treeData", prd_ln],
    refetchOnWindowFocus: false,

    retry: 3, // Retry the query up to 3 times in case of failure
    staleTime: 30000, // 30 seconds
    cacheTime: 5 * 60 * 1000, // 5 minutes

    onError: (error) => {
      console.error("Error fetching questions:", error);
    },
  });
};

export default useGetTreeData;
