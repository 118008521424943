import React, { Fragment, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { common } from '../../utills/static-text';
import Loading from '../UI/Loading';
import SampleCSVTable from './SampleCSVTable';
import UploadCSVInput from './UploadCSVInput';
import useUpdateModelListData from '../../hooks/updateModelListData';
import { useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../context/AlertContext';

const UploadModelListForm = ({ documentProperty }) => {
  const [csvData, setCsvData] = useState({});
  const [errors, setErrors] = useState({}); // State to hold error messages
  const updateModelListData = useUpdateModelListData();
  const queryClient = useQueryClient();
  const showAlert = useAlert();

  const payload = {
    prd_ln: documentProperty?.prd_ln,
    doc_id: documentProperty?.doc_id,
    name: documentProperty?.name,
    file: csvData,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    // Validation
    if (!documentProperty?.name) {
      newErrors.documentName = 'Document Name is required.';
    }
    if (!csvData || Object.keys(csvData).length === 0) {
      newErrors.csvData = 'CSV data is required.';
    }

    // Set errors if any
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent form submission
    }

    // Proceed with form submission logic
    updateModelListData.mutate(
      {
        ...payload,
        id: documentProperty?.id,
        soft_delete: true,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ['questions'],
          });
        },
        onError: (error) => {
          showAlert(error.message || 'Something went wrong.', 'error');
        },
      }
    );
    // Reset errors if needed
    setErrors({});
  };

  return (
    <Fragment>
      {false ? (
        <Loading isIconOnly={true} />
      ) : (
        <Fragment>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Document Name</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                type="text"
                placeholder={documentProperty?.name}
                disabled
              />
              {errors.documentName && (
                <div className="text-danger">{errors.documentName}</div>
              )}
            </Form.Group>

            <UploadCSVInput setCsvData={setCsvData} csvData={csvData} />
            {errors.csvData && (
              <div className="text-danger">{errors.csvData}</div>
            )}

            <SampleCSVTable common={common} />
            <div className="text-end">
              <Button
                variant="outline-secondary"
                type="submit"
                disabled={updateModelListData.isPending}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UploadModelListForm;
