// import { remove } from "lodash";
import { getAPIQuery } from "../utills/utills";
import { get, post, put, remove } from "./http.service";

export const Configuration = {
  getEquipmentList: () => get(`equipments`),
  getEquipmentById: (query) => get(`equipments${getAPIQuery(query)}`),
  updateEquipmentById: (options) => {
    switch (options.method) {
      case "PUT":
        return put(`equipments`, options.body);
      case "POST":
      default:
        return post(`equipments`, options.body);
    }
  },
  addProblem: (options) => post(`problem${getAPIQuery(options.query)}`, options.body),
  editProblem: (options) => put(`problem${getAPIQuery(options.query)}`, options.body),
  deleteProblem: (query) => remove(`problem${getAPIQuery(query)}`),
  searchProblem: (options) => post(`problem/search${getAPIQuery(options.query)}`, options.body),
};

export const Authoring = {
  getDocumentList: () => get(`questions`),
  getDocument: (query) => get(`questions${getAPIQuery(query)}`),
  getTreeData: (query) => get(`treedata${getAPIQuery(query)}`),
  getModelData: (query) => get(`modeldata${getAPIQuery(query)}`),
  createDocument: (options) => {
    return post(`questions`, options.body);
  },
  searchText: (options) => {
    return post(`search${getAPIQuery(options.query)}`, options.body);
  },
  updateTreeNodeById: (options) => {
    switch (options.method) {
      case "PUT":
        return put(
          `treedata/treenode${getAPIQuery(options.query)}`,
          options.body
        );
      case "POST":
      default:
        return post(
          `treedata/treenode${getAPIQuery(options.query)}`,
          options.body
        );
    }
  },
  lockTreeById: (options) => put(`questions/lock${getAPIQuery(options.query)}`, options.body),
  deployTreeById: (options) => put(`deploy${getAPIQuery(options.query)}`, options.body || {}),
  addNodeById: (options) => post(`treedata/treenode${getAPIQuery(options.query)}`, options.body),
  deleteNodeById: (options) => remove(`treedata/treenode${getAPIQuery(options.query)}`),
  copyNode: (options) => post(`copy/treedata/treenode${getAPIQuery(options.query)}`, options.body),
  linkNode: (options) => post(`link/treedata/treenode${getAPIQuery(options.query)}`, options.body),

  // Model List
  putModelListById: (options) => put(`model_list${getAPIQuery(options.query)}`, options.body || {}),
  AddModelListById: (options) => post(`model_list${getAPIQuery(options.query)}`, options.body || {}),
};

export const Deployment = {
  deployTreeByIds: (options) => put(`deploy${getAPIQuery(options.query)}`, options.body || {}),
}
