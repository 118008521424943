import _isEmpty from "lodash/isEmpty";
import { Form, OverlayTrigger } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { GETooltip, SVGComponent } from "../../components";
import { common } from "../../utills/static-text";

const AuthoringDocumentFilter = ({
  filterByConf,
  selectedFilter,
  setSelectedFilter,
  setCheckDecisionTree,
  checkDecisionTree,
  setCheckModelList,
  checkModelList,

  handelAddDocument,
}) => {
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    setShowBackButton(!_isEmpty(selectedFilter));
  }, [selectedFilter]);

  const handleSelect = (filter) => {
    setSelectedFilter(filter);
  };

  const handleBackButton = () => {
    setShowBackButton(false);
    setSelectedFilter("");
  };

  return (
    <section className="p-2 authoring-filter">
      <InputGroup
        className={`d-flex justify-content-start align-items-center mb-2 ${
          selectedFilter !== "" ? "active" : ""
        }`}
      >
        <Form.Check
          type="checkbox"
          label={common.decision_tree}
          checked={checkDecisionTree}
          className="me-2"
          onChange={(event) => {
            handleBackButton();

            setCheckDecisionTree(event.target.checked);
          }}
        />

        <Form.Check
          type="checkbox"
          label={common.model_list}
          checked={checkModelList}
          className="me-2"
          onChange={(event) => {
            handleBackButton();
            setCheckModelList(event.target.checked);
          }}
        />

        <OverlayTrigger
          placement="top"
          overlay={GETooltip({
            msg: common.btn_new_document,
          })}
        >
          <Button
            variant="outline-light"
            className="btn-outline-light text-dark"
            onClick={handelAddDocument}
          >
            <span className="svg-primary">
              {" "}
              <SVGComponent.NewDocument />{" "}
            </span>
          </Button>
        </OverlayTrigger>

        {filterByConf && (
          <span className="svg-primary">
            <DropdownButton
              id="dropdown-variants-light"
              variant="top"
              title={<SVGComponent.Filter />}
              disabled={filterByConf.length === 0}
            >
              {filterByConf?.map((option, index) => (
                <Dropdown.Item
                  key={`case-base-${index}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </span>
        )}
      </InputGroup>

      {selectedFilter && (
        <div
          className={`d-flex justify-content-start authoring-filter-header ${
            selectedFilter && "active"
          }`}
        >
          <InputGroup.Text className="flex-fill flex-column border-0">
            <div className="m-0 p-0">
              <div className="fw-bold">{selectedFilter.name}</div>
            </div>
          </InputGroup.Text>
          {showBackButton && (
            <Button
              variant="outline-antiquewhite"
              className="btn-outline-antiquewhite text-dark border-0"
              onClick={handleBackButton}
            >
              <i className="fa fa-close text-danger" aria-hidden="true"></i>
            </Button>
          )}
        </div>
      )}
    </section>
  );
};

export default AuthoringDocumentFilter;
