import React from 'react';

const StageCards = ({ getDashboardData, activeCaseBase }) => {
  const { draft, staging, production } =
    getDashboardData?.data?.statistics || {};
  return (
    <div>
      <div className="row mb-4">
        <div className="col-xs-4 col-lg-4 col-md-4 text-center">
          <div className="card draft cascading-admin-card">
            <div className="admin-up">
              {activeCaseBase === 'ALL' ? (
                <i className="fa fa-line-chart bg-secondary fs-4"></i>
              ) : (
                <i className="fa bg-secondary fs-4">
                  {activeCaseBase.charAt(0)}
                </i>
              )}
              <div className="data text-uppercase">
                {activeCaseBase === 'ALL' ? (
                  <p>DRAFT</p>
                ) : (
                  <p>{activeCaseBase} in DRAFT</p>
                )}
                <h4>
                  <strong>{draft?.count}</strong>
                </h4>
              </div>
            </div>
            <div className="card-body">
              <div className="progress">
                <div
                  aria-valuemax="100"
                  aria-valuemin="0"
                  aria-valuenow={draft?.percentage}
                  className="progress-bar bg grey"
                  role="progressbar"
                  style={{
                    width: `${draft?.percentage}%`,
                  }}
                ></div>
              </div>
              <p className="card-text mt-3">{draft?.label}</p>
            </div>
          </div>
        </div>

        <div className="col-xs-4 col-lg-4 col-md-4 text-center">
          <div className="card staging cascading-admin-card">
            <div className="admin-up">
              {activeCaseBase === 'ALL' ? (
                <i className="fa fa-line-chart bg-warning fs-4"></i>
              ) : (
                <i className="fa bg-warning fs-4">{activeCaseBase.charAt(0)}</i>
              )}
              <div className="data text-uppercase">
                {activeCaseBase === 'ALL' ? (
                  <p>STAGING</p>
                ) : (
                  <p>{activeCaseBase} in STAGING</p>
                )}
                <h4>
                  <strong>{staging?.count}</strong>
                </h4>
              </div>
            </div>
            <div className="card-body">
              <div className="progress">
                <div
                  aria-valuemax="100"
                  aria-valuemin="0"
                  aria-valuenow={staging?.percentage}
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{
                    width: `${staging?.percentage}%`,
                  }}
                ></div>
              </div>

              {staging?.count === 0 && draft?.count >= 0 ? (
                <p className="card-text mt-3">
                  {draft?.count + staging?.count}
                  waiting to be push in staging.
                </p>
              ) : (
                <p className="card-text mt-3">{staging?.label}</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-xs-4 col-lg-4 col-md-4 text-center">
          <div className="card cascading-admin-card production">
            <div className="admin-up">
              {activeCaseBase === 'ALL' ? (
                <i className="fa fa-line-chart bg-success fs-4"></i>
              ) : (
                <i className="fa bg-success fs-4">{activeCaseBase.charAt(0)}</i>
              )}
              <div className="data text-uppercase">
                {activeCaseBase === 'ALL' ? (
                  <p>PRODUCTION</p>
                ) : (
                  <p>{activeCaseBase} in PRODUCTION</p>
                )}
                <h4>
                  <strong>{production?.count}</strong>
                </h4>
              </div>
            </div>
            <div className="card-body">
              <div className="progress">
                <div
                  aria-valuemax="100"
                  aria-valuemin="0"
                  aria-valuenow={production?.percentage}
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{
                    width: `${production?.percentage}%`,
                  }}
                ></div>
              </div>

              {production?.count === 0 &&
              getDashboardData?.data?.statistics?.staging?.count >= 0 ? (
                <p className="card-text mt-3">
                  {draft?.count +
                    getDashboardData?.data?.statistics?.staging?.count}{' '}
                  document(s) waiting to be reviewed.
                </p>
              ) : (
                <p className="card-text mt-3">{production?.label}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageCards;
