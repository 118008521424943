import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DocumentCard from '../Authoring/DocumentCard';

const DocumentListCards = ({ getDashboardData, activeCaseBase }) => {
  const { documentList } = getDashboardData?.data || [];
  return (
    <div>
      {activeCaseBase !== 'ALL' && (
        <Fragment>
          <h5 className="text-danger">
            <i className="fa-regular fa-copy"></i> Document(s) case base
          </h5>
          <div className="row">
            {documentList?.filter((item) => item.conf_name === activeCaseBase)
              .length ? (
              <Fragment key={activeCaseBase}>
                {documentList?.map((item) => (
                  <Fragment key={item.id}>
                    {item.conf_name === activeCaseBase && (
                      <div className="col-xs-4 col-lg-4 col-md-4 text-center mb-2">
                        <Link
                          to={`/authoring/tree/${item.prd_ln}/${item.start_id}/${item.id}`}
                          key={`link-${item.id}-${item.id}`}
                          className="text-decoration-none display-block"
                        >
                          <div
                            className="col-12 col-sm-12 col-xs-12 d-flex"
                            style={{ minHeight: '100%' }}
                          >
                            <DocumentCard item={item} isDeployment={false} />
                          </div>
                        </Link>
                      </div>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ) : (
              <div className="col-12">No data!</div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DocumentListCards;
